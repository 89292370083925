// Cool reference for SCSS color variables: https://dev.to/nicolalc/a-cool-way-to-handle-scss-color-variables-3njp

// @font-face {
//   font-family: 'Roboto';
//   src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
// }

// @font-face {
//   font-family: 'Roboto Mono';
//   src: local('Roboto Mono'), url(./assets/fonts/Roboto_Mono/static/RobotoMono-Regular.ttf) format('truetype');
// }

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$programmer-peacock: #003046;
$tuple-turquoise: #43D6B9;
$output-orange: #FA7815;
$binary-blue: #0D38D3;
$pixel-peach: #F9B88C;
$wireless-white: #FFF;
$gigabyte-grey: #7A7A78;
$gui-grey: #CECECE;
$wavy-white: #EDEDED;

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mono {
  font-family: 'Roboto Mono', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.text-white {color: $wireless-white}
.text-peacock {color: $programmer-peacock}
.text-turq {color: $tuple-turquoise}
.text-orange {color: $output-orange}
.text-blue {color: $binary-blue}
.text-peach {color: $pixel-peach}
.text-dark-grey {color: $gigabyte-grey}
.text-grey {color: $gui-grey}
.text-light-grey {color: $wavy-white}

.bg-white {background-color: $wireless-white}
.bg-peacock {background-color: $programmer-peacock}
.bg-turq {background-color: $tuple-turquoise}
.bg-orange {background-color: $output-orange}
.bg-blue {background-color: $binary-blue}
.bg-peach {background-color: $pixel-peach}
.bg-dark-grey {background-color: $gigabyte-grey}
.bg-grey {background-color: $gui-grey}
.bg-light-grey {background-color: $wavy-white}

.bg-hack-grey {background-color: #f7f7f7}

.border-turq { border: 8px solid $tuple-turquoise }
.border-orange { border: 8px solid $output-orange }
.border-blue { border: 8px solid $binary-blue }
.border-peach { border: 8px solid $pixel-peach }

// MAIN SITE FONTS
h1 {
  font-size: 136px;
  font-weight: 700;
  line-height: 159.38px;
}
h2 {
  font-size: 56px;
  font-weight: 500;
  line-height: 60px;
}
h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 37.5px;
}
h4 {
  font-size: 64px;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -3%;
}
p {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 5%;
}
.p2 {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 5%;
}
.interactive {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 5%;
}

// GHH SITE FONTS
h2.hack {
  font-size: 56px;
  font-weight: 700;
  line-height: 66px;
}
h3.hack {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
}
h4.hack {
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
}
p.hack {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 5%;
}
.nav .hack {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 5%;
}

.button-unstyled {
  border: 0px;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0;
}
.full-screen {
  height: 100vh;
}
.link-button {
  border: 0px;
  background: $programmer-peacock;
  color: $wireless-white;
}
.link-button:hover, .link-button:active {
  background: $wireless-white;
  color: $programmer-peacock;
}
.circleImg {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  object-fit: cover;
}

@media only screen and (max-device-width: 480px) {
  // MAIN SITE FONTS
  h1 {
    font-size: 56px;
    line-height: 65.63px;
  }
  h2 {
    font-size: 32px;
    line-height: 37.5px;
  }
  h3 {
    font-size: 20px;
    line-height: 23.44px;
  }
  h4 {
    font-size: 32px;
    line-height: 42.2px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
  }

  // GHH SITE FONTS
  h2.hack {
    font-size: 32px;
    line-height: 37px;
    text-transform: uppercase;
  }
  h3.hack {
    font-size: 20px;
    line-height: 26px;
  }
  h4.hack {
    font-size: 16px;
    line-height: 21px;
  }
  p.hack {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 5%;
  }
  .nav .hack {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 5%;
  }
}

.hoverColor:hover {
  color: $output-orange
}

.navbartext{
  color: $wireless-white;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 5%;
  font-family: 'Roboto Mono', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  text-decoration: none;
}

.signUpTextDesktop {
  margin-top: 105px;
  margin-left: 83px;
}

.signUpTextMobile {
  margin-top: 47px;
  margin-left: 34px
}

.footerLogoDesktop {
  margin-top: 100px;
  margin-left: 140px;
}
.footerLogoMobile {
  margin-top: 20px;
  margin-bottom: -40px;
  margin-left: 25px
}

.footerIconRowDesktop {
  margin-top: 250px;
  margin-left: 140px;
  z-index: 100
}
.footerIconRowMobile {
  margin-top: 70px;
  margin-left: 25px;
  z-index: 100;
}

.footerLink {
  z-index: 100;
}

.footerIconDesktop {
  margin-right: 24px;
}

.footerIconMobile {
  width: 65%;
  display: inline;
}

.footerEmailMobileColumn {
  color: $wireless-white;
  font-size: 14px;
  margin-top: 30px;
  margin-left: 210px;
  position: absolute;
}

.footerEmailMobile{
  color: $wireless-white;
  font-size: 14px;
  border-collapse: separate;
  border-spacing: 5px;
  margin-top: 0.5px;
  margin-left: 0px;
}

.footerMLHCodeDesktop{
  margin-top: 500px;
  margin-left: 140px;
}

.footerMLHCodeMobile{
  margin-top: 100px;
  margin-left: 25px;
}

.footerArchiveMobileRow{
  color: $wireless-white;
  font-size: 14px;
  margin-top: 125px;
  margin-left: 25px;
  position: absolute;
}

.footerArchiveMobile{
  color: $wireless-white;
  font-size: 14px;
  margin-left: 5px;
}

.hovIcon {
  display: none;
}
.normIcon:hover {
  display: none;
}
.normIcon:active {
  display: none;
}
.normIcon:hover + .hovIcon {
  display: block;
}
.normIcon:active + .hovIcon {
  display: block;
}

.largeSponsor {
  transform: scale(1.4);
}
.largeSponsor:hover {
  transition: transform .2s;
  transform: scale(1.5);
}
.largeSponsor:active {
  transition: transform .2s;
  transform: scale(1.5);
}

.largeSponsorMobile {
  transform: scale(1.0005);
}

.sponsorImage {
  transition: transform .2s;
}
.sponsorImage:hover {
  transition: transform .2s;
  transform: scale(1.1);
}
.sponsorImage:active {
  transition: transform .2s;
  transform: scale(1.1);
}

.signupbanner{
  margin-top:-5px;
}

.signupbannermobile{
  margin-top:-5px;
}

.mlhbanner {
  margin-top:-12px;
}

.mlhbannermobile {
  margin-top: -10px;
  margin-right: 10px;
}

.sponsorshipProspectus {
  width: 800px;
  height: 600px;
}

.Navbar {
  position: fixed;
  left: 12px; 
  top: 35px;
  z-index: 9999;
  width: 100%;
}

.navlinks {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.navbaradjustments {
  position: inherit;
  background-color: transparent;
  margin-top: 40px;
  margin-right: 230px;
  font-size: 30px;
}

.textadjustments{
  margin-bottom: 3.5em;
  margin-right: 6.25em;
  flex-wrap: wrap;
}


.banners {
  position: fixed;
  right: 2em;
  top: 1em;
  flex-direction: row;
  justify-content: flex-start;
}

.adjustments{
  position: relative;
}

.ghhlogo {
  margin-left: 30px;
}

.mobilelinks{
  padding-left: 20px;
  padding-top: 60px;
}

.mobilenavfooter{
  height: 85px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#003046;
}
.discordinvite:hover, .discordinvite:active {
  color: $binary-blue;
}

.newsletter:hover, .newsletter:active {
  color: $output-orange;
}

.remove-underline:hover{
  text-decoration: underline;
}

.remove-underline{
  text-decoration:none;
}

// useful for debugging
// * {
//   border: 1px solid red;
// }
